import component from "svelte-tag";
import App from './App.svelte';
const app = new component({component:App,tagname:"milestone-timeline"})

// const app = new App({
// 	target: document.body,
// 	props: {
// 		name: 'world'
// 	}
// });

// const app = new App();

export default app;